import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { useToast } from 'vue-toastification/composition';
import '@core/scss/vue/libs/vue-sweetalert.scss';  
import 'animate.css';
import router from '@/router';

Vue.use(VueSweetalert2);

export default function useNotifications() {

	const toast = useToast()

	const toastNotification = (title, success = true) => {

		toast({
			component: ToastificationContent,
			props: {
				title,
				icon: success ? 'CheckIcon' : 'AlertTriangleIcon',
				variant: success ? 'success' : 'danger'
			}
		})
	}

	const swalNotification = (message, success = true, timer = 2000) => {

		const data = {
			icon: 'success',
			title: message,
			timer
		}
		if (!success) {
			delete data.timer 

			data.icon = 'error'
			data.title = 'Error'
			data.text = message
			data.allowOutsideClick =  true
		}

		Vue.swal({
			...data,
			showConfirmButton: false,
			showCancelButton: false
		})
	}

	const swalNotificationTimer = (
		{ 
			tenMinutes = false, 
			fiveMinutes = false, 
			threeMinute = false, 
			timer = 4000 
		} = {}
	) => {

		const minutes = tenMinutes
			? "10"
			: fiveMinutes
			? "5"
			: threeMinute
			? "3"
			: null

		if (minutes) {
			Vue.swal({
				icon: 'warning',
				title: `¡Te quedan ${minutes} minutos!`,
				html: '<small>Recuerda guardar tus cambios ...</small>',
				timer,
				showConfirmButton: false,
				showCancelButton: false
			})
		}
	}

	const swalNotificationCloser = ({ goTo } = {}) => {

		let timerInterval
		let countdown = 3
		
		Vue.swal({
			icon: "info",
			title: `Enviando información en ${countdown} ...`,
			/* html: "Tiempo terminado <b></b>", */
			text: "Tiempo terminado",
			timer: countdown * 1000,
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			timerProgressBar: false,
			didOpen: () => {
				/* Vue.swal.showLoading() */
				/* timerInterval = setInterval(() => {
					countdown--
					Vue.swal.update({
						title: `Enviando información en ${countdown} ...`
					})
				}, 1000) */
				timerInterval = setInterval(() => {
					countdown--;
					if (countdown >= 0) {
					  Vue.swal.update({
						title: `Enviando información en ${countdown} ...`
					  });
					} else {
					  clearInterval(timerInterval);
					}
				  }, 1000);
			},
			willClose: () => {
				clearInterval(timerInterval)
			}
		}).then(() => {
			if (goTo) router.push({ name: goTo })
		})

	}

	return { toastNotification, swalNotification, swalNotificationTimer, swalNotificationCloser };
}